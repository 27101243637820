@import "variables";

/******* Base styles *******/

body {
  /* https://atease.atlassian.net/wiki/spaces/AE/pages/2719765/Branding+Style+Guide#Typography */
  font-family: "Inter", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased; /* For Safari and other WebKit browsers */
  -moz-osx-font-smoothing: grayscale; /* For Firefox on macOS */
}

.c-grid-fluid,
.c-grid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.header__user img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 1;
}

.hamburger-box {
  width: 24px;
  height: 17px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 24px;
  height: 3px;
  background-color: #525252;
  border-radius: 0;
  position: absolute;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -7px;
}
.hamburger-inner::after {
  bottom: -7px;
}

/*
 * Squeeze
 */
.hamburger--squeeze .hamburger-inner {
  -webkit-transition-duration: 0.075s;
  transition-duration: 0.075s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  -webkit-transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  -webkit-transition: bottom 0.075s 0.12s ease, -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.12s ease, -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition-delay: 0.12s;
  transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  -webkit-transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: bottom 0.075s ease, -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s ease, -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.icon-billing-settings:before {
  content: "\e001";
}

.icon-calendar:before {
  content: "\e002";
}

.icon-dashboard:before {
  content: "\e003";
}

.icon-inbox:before {
  content: "\e004";
}

.icon-inspections:before {
  content: "\e005";
}

.icon-marketplace:before {
  content: "\e006";
}

.icon-properties:before {
  content: "\e007";
}

.icon-revenue:before {
  content: "\e008";
}

.icon-taxes:before {
  content: "\e009";
}

html,
body {
  width: 100%;
  min-height: 100vh;
}
html.is-hideScroll,
body.is-hideScroll {
  overflow: hidden !important;
  width: 100%;
}

html {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  overflow-x: hidden;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
  background-color: #ffffff;
  line-height: 1.42857143;
  font-size: 14px;
  font-weight: normal;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > .main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
body > .main--start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 100%;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button[disabled] {
  opacity: 0.55;
  cursor: not-allowed;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
hr {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}

a {
  outline: none;
  text-decoration: none;
}
a:hover,
a:focus {
  text-decoration: none;
}

input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

picture {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-grid {
  max-width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}
@media screen and (max-width: 767px) {
  .c-grid {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.c-grid-fluid {
  max-width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}

.c-form__group {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.c-form__group[data-form-group-mod="2"] {
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 1023px) {
  .c-form__group[data-form-group-mod="2"] {
    grid-template-columns: 1fr;
  }
}
.c-form__group[data-form-group-mod="4"] {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media screen and (max-width: 1023px) {
  .c-form__group[data-form-group-mod="4"] {
    grid-template-columns: 1fr;
  }
}
.c-form__field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 20px;
}
.c-form__field input:not(input[type="checkbox"]),
.c-form__field select {
  outline: none;
  position: relative;
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0 15px;
  border-radius: 6px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #cacaca;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  font-weight: 400;
}
.c-form__field input.placeholder,
.c-form__field select.placeholder {
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 400;
}
.c-form__field input:-moz-placeholder,
.c-form__field select:-moz-placeholder {
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 400;
}
.c-form__field input::-moz-placeholder,
.c-form__field select::-moz-placeholder {
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 400;
}
.c-form__field input:-ms-input-placeholder,
.c-form__field select:-ms-input-placeholder {
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 400;
}
.c-form__field input::-webkit-input-placeholder,
.c-form__field select::-webkit-input-placeholder {
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 400;
}
.c-form__field select:invalid {
  color: #a0a0a0;
}
.c-form__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 4px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
}
.c-form__label svg {
  margin-left: 10px;
  margin-right: 10px;
}
.c-form__label span {
  color: #7a7a7a;
  font-size: 13px;
}
.c-form__info {
  padding-top: 5px;
  color: #7a7a7a;
  font-size: 13px;
  font-weight: 400;
}
.c-form__file {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .c-form__file {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.c-form__file input[type="file"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
.c-form__file-preview {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background-color: #f5f5f5;
}
.c-form__file span {
  color: #7a7a7a;
  font-size: 14px;
  font-weight: 400;
}

.icon-font {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1;
}

.logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.logo__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.logo__btn img {
  display: block;
  max-width: 100%;
  height: auto;
}

.c-title {
  color: rgba(0, 0, 0, 0.87);
}
.c-title[data-title-mod="1"] {
  font-size: 96px;
  font-weight: 800;
  line-height: 112px;
  letter-spacing: -1.5px;
}
.c-title[data-title-mod="2"] {
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -0.5px;
}
.c-title[data-title-mod="3"] {
  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
}
.c-title[data-title-mod="4"] {
  font-size: 34px;
  font-weight: 500;
  line-height: 36px;
}
.c-title[data-title-mod="5"] {
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.18px;
}
.c-title[data-title-mod="6"] {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.c-title[data-title-weight="500"] {
  font-weight: 500;
}
.c-title[data-title-align="center"] {
  text-align: center;
}

.c-subtitle[data-subtitle-mod="1"] {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.c-subtitle[data-subtitle-mod="2"] {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.1px;
}
.c-subtitle[data-subtitle-align="center"] {
  text-align: center;
}

.c-body {
  color: rgba(0, 0, 0, 0.87);
}
.c-body[data-body-mod="1"] {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.c-body[data-body-mod="2"] {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.c-body[data-body-align="center"] {
  text-align: center;
}

.c-btn {
  outline: none;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: auto;
  height: 32px;
  padding: 4px 16px;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2rem;
  background-color: #00a6ce;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
@media screen and (max-width: 767px) {
  .c-btn {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.c-btn.c-btn--white {
  background-color: #ffffff;
  color: #757575;
}
.c-btn.c-btn--lighten {
  background-color: #00bed6;
}
.c-btn.c-btn--big {
  height: 50px;
}
.c-btn[data-btn-transform="upper"] {
  text-transform: uppercase;
}
.c-btn[data-btn-weight="700"] {
  font-weight: 700;
}
.c-btn__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.c-btn__wrapper--center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.c-btn__wrapper--between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
  background-color: #fff;
}
.header__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 64px;
  padding-left: 4px;
  padding-right: 35px;
}
@media screen and (max-width: 767px) {
  .header__wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
.header__wrapper > div:nth-of-type(1) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header__action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header__action > div:nth-of-type(2) {
  width: 1px;
  height: 32px;
  margin-left: 20px;
  margin-right: 10px;
  background-color: #d8d8d8;
}
.header__user {
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.header .hamburger {
  margin-right: 28px;
}

.profile-settings__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: calc(100vh - 64px);
  padding-bottom: 15px;
}
.profile-settings__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 96px;
  padding: 24px 20px 0 4px;
  -webkit-box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .profile-settings__header {
    padding-right: 4px;
  }
}
.profile-settings__body {
  padding: 40px 20px 40px 4px;
}
@media screen and (max-width: 767px) {
  .profile-settings__body {
    padding-right: 4px;
  }
}
.profile-settings__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 90px;
  padding: 20px;
}
@media screen and (max-width: 767px) {
  .profile-settings__footer {
    padding-left: 4px;
    padding-right: 4px;
  }
}
.profile-settings__title {
  padding-bottom: 5px;
  color: #243746;
  font-size: 18px;
  font-weight: 700;
}
.profile-settings__subtitle {
  color: #525252;
  font-size: 16px;
  font-weight: 400;
}
.profile-settings__line {
  margin-top: 7px;
  margin-bottom: 13px;
  border-top: 1px solid #e0e0e0;
}
.profile-settings__heading {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  color: #000;
  font-size: 18px;
  font-weight: 600;
}
.profile-settings__desc {
  padding-bottom: 10px;
  color: #525252;
  font-size: 14px;
  font-weight: 400;
}
.profile-settings__form {
  padding-left: 20px;
  padding-right: 20px;
}
@media screen and (max-width: 767px) {
  .profile-settings__form {
    padding-left: 4px;
    padding-right: 4px;
  }
}
.profile-settings__form-1 {
  padding-bottom: 40px;
}
.profile-settings__new {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.profile-settings__new i {
  margin-right: 10px;
  color: #00a6ce;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.006px;
}
.profile-settings__new span {
  color: #00a6ce;
  font-size: 14px;
  font-weight: 700;
}
.profile-settings__btn {
  width: 100%;
}
.profile-settings__btn .c-btn {
  width: 100%;
  max-width: 215px;
}
